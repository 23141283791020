<template>
    <div class="mx-4">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
  props: [],
  data () {
    return {}
  },
  computed: {
    clubName () {
      return ''
    }
  },
  methods: {},
  watch: {},
  mounted () {},
  components: {}
}
</script>
